//$( document ).on('turbolinks:load', function() {
$( document ).ready(function() {

    var rcolor = getRandomDarkColor();

    $.expr[":"].contains = $.expr.createPseudo(function(arg) {
        return function( elem ) {
            return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
        };
    });

    $(".backgroundable").each(function() {
        $(this).css({"background":rcolor});
    });

    $(".bright-backgroundable").each(function() {
        $(this).css({"background":getRandomBrightColor()});
    });

    $(".city-map").each(function() {
        let $this = $(this);
        let geo = $this.attr("geopoint").split(",");
        let map = L.map('map').setView([geo[0], geo[1]], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);

    });

    $('.lightbox-link').simpleLightbox({ /* options */ });

    $(".rating-point").on("mouseover", function () {
		var rindex = $(this).index();
		var i = 0;
		$(this).parent().find(".rating-point").each(function () {
			if (i <= rindex) $(this).addClass("hovered"); else $(this).removeClass("hovered");
			i++;
		});
		$(this).closest(".rating-point").on("mouseout", function () {
			$(this).parent().find(".rating-point").removeClass("hovered");
		});
    });

    $("#countriesSearchForm").submit(function (e) {
		e.preventDefault();
    });

    $("#countriesSearchInput").keyup(function() {
        let $this = $(this);
        let this_text = $this.val();
        $(".countries-list__country").hide();
        $(".countries-list__country .hidden-name:contains('"+this_text+"')").parent().show();
    });
    
	$(".rating-point").click(function () {
		var rindex = $(this).index();
		var i = 0;
		$(this).parent().find(".rating-point").each(function () {
			if (i <= rindex) $(this).addClass("active"); else $(this).removeClass("active");
			i++;
		});
    });

    $(".preheader__search-toggle").click(function () {
        let $this = $(this);
        $("#preheaderSearchIcon").toggleClass("hidden");
        $("#preheaderSearchForm").toggleClass("hidden");
    });

	$(window).resize(function() {
        
    });

    $('.cke').each(function() {
        var id = $(this).attr('id');
        if (!CKEDITOR.instances[id]) {
           CKEDITOR.replace(id);
    }});

    $(".link-review").click(function (e) {
        e.preventDefault();
        $(".link-review").addClass("active");
        $("#reviewForm").slideToggle();
    });

    $("#new_comment").on("ajax:success", function(event) {
        event.preventDefault();
        let $this = $(this);
        let comment_text = $this.find("textarea").val();
        let comment_author = $this.find("#newCommentSignedAs").text();
        let comment_date = "только что";
        let comment_avatar = $this.find("#newCommentAvatar").attr("src");
        let commentTemplate = $("#newCommentTemplate").html();
        var $commentRow = $(commentTemplate);
        $commentRow.find("div[template='text']").text(comment_text);
        $("#reviewForm").slideUp( "fast", function() {
            $(".reviews").prepend($commentRow);
            $(".new-review").slideDown();
            $(".new-review").addClass("newest");
            $(".no-reviews").slideUp();
        });
      }).on("ajax:error", function(event) {
        alert("fail, kys");
      });
      
});

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
}

function getRandomDarkColor() {
    let color = "rgb(" + getRandomInt(10,60) + "," + getRandomInt(10,60) + "," + getRandomInt(10,60) + ")";
    return color; 
}

function getRandomBrightColor() {
    let color = "rgb(" + getRandomInt(200,250) + "," + getRandomInt(200,250) + "," + getRandomInt(200,250) + ")";
    return color; 
}
