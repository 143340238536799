// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
//require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

//= require jquery.min
//= require owl.carousel.min
//= require simpleLightbox.min

global.jQuery = require('vendor/jquery.min');
var $ = global.jQuery;
window.$ = $;
let owl_carousel = require('vendor/owl.carousel.min');
window.fn = owl_carousel;
require("vendor/simpleLightbox.min")
require("vendor/leaflet")
//require("vendor/datepicker.min")

require("packs/ui")
require("packs/main")