var window_height;
var window_width;

var sm_width = 720;
var md_width = 1280;
var current_mode = 0;

//document.addEventListener("turbolinks:load", function() {
$( document ).ready(function() {

    var $body = $("body");

    regulateMode();

    $(window).resize(function () {
        regulateMode($body);
    });

    //$('[data-toggle="datepicker"]').datepicker();

    $('.lightbox-link').simpleLightbox();

    $(".dropdown-container").mouseenter(function () {
        let $this = $(this);
        if (window_width > sm_width) {
            $this.find(".dropdown-link").addClass("active");
            $this.find(".menu-dropdown").stop().fadeIn(400);
        }
    });

    $(".menu-dropdown").mouseleave(function () {
        if (window_width > sm_width) {
            let $this = $(this);
            $this.closest(".dropdown-container").find(".dropdown-link").first().removeClass("active");
            $this.stop().fadeOut(400);
        }
    });

    $(".dropdown-link").click(function () {
        let $this = $(this);
        if (window_width < sm_width) {
            $this.parent().find(".menu-dropdown").stop().slideToggle();
            $this.toggleClass("active");
        }
    });

    $(".range-slider").each(function () {
        let $this = $(this);
        var min_data = parseInt($this.attr("data-min"));
        var max_data = parseInt($this.attr("data-max"));
        $this = $this[0];
        noUiSlider.create($this, {
            start: [min_data, max_data],
            connect: true,
            range: {
                'min': min_data,
                'max': max_data
            }
        });
    });

    $(".link-modal").click(function (e) {
        e.preventDefault();
        let target_id = "#" + $(this).attr("data-modal");
        $(target_id).addClass("active");
    });

    $(".tabs-link").click(function (e) {
        e.preventDefault();
        let $this = $(this);
        let this_id = $this.attr("href");
        $this.parents(".tabs-wrapper").find('.tab-item').removeClass("active");
        $this.parents(".tabs-wrapper").find(".tab").removeClass("active");
        $(this_id).addClass("active");
        $this.parent().addClass("active");
    });

    $(".modal-close").click(function (e) {
        e.preventDefault();
        $(this).closest(".modal").removeClass("active");
    });

    $('.dropdown').click(function () {
        $(this).attr('tabindex', 1).focus();
        $(this).toggleClass('active');
        $(this).find('.dropdown-menu').slideToggle(300);
    });

    $('.dropdown').focusout(function () {
        $(this).removeClass('active');
        $(this).find('.dropdown-menu').slideUp(300);
    });

    $('.dropdown .dropdown-menu li').click(function () {
        $(this).parents('.dropdown').find('span').text($(this).text());
        $(this).parents('.dropdown').find('input').attr('value', $(this).attr('id'));
    });
    
    $('#loginDropButton .menu li a').click(function () {
        let $this = $(this);
        $this.parents('.btn-group').attr("data-social", $this.parents("li").attr("data-social"));
        $this.parents('.btn-group').find(".dropdown-login__button span").html($this.html());
    });


    $(".mobile-button").click(function (e) {
        e.preventDefault();
        $(this).toggleClass("active");
        $(".header").slideToggle();
    });

    $(".accordion.active").find(".accordion-body").slideDown("fast");

    $(".accordion-header").click(function () {
        let $this = $(this);
        $this.parent().toggleClass("active");  
        $this.parent().find(".accordion-body").stop().slideToggle(300);
    });

    function regulateMode() {
        window_height = window.innerHeight;
        window_width = $('body').innerWidth();;
        $body.removeAttr("class");
        $(".header").removeAttr("style");
        //console.log("width: ", window_width, "height:", md_width);
        if (window_width > md_width) {
            current_mode = 0;
            $body.addClass("normal");
            $(".mobile-button").removeClass("active");
            $(".header").removeAttr("style");
        }
        else if (window_width < sm_width) {
            current_mode = 2;
            $body.addClass("mobile");
        }
        else {
            current_mode = 1;
            $body.addClass("tablet");
        }
    }

});